<template>
  <div class="admin-dashboard">
    <el-row>
      <el-col :span="8">
        <el-card>
          <div slot="header" class="clearfix-now">
            <span>Requests hiện tại</span>
          </div>
          <div align="center">{{ allRequestSystem.currentRequests }}</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div slot="header" class="clearfix-day">
            <span>Requests theo ngày</span>
          </div>
          <div align="center">{{ allRequestSystem.dailyRequests }}</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div slot="header" class="clearfix-month">
            <span>Requests theo tháng</span>
          </div>
          <div align="center">{{ allRequestSystem.monthlyRequests }}</div>
        </el-card>
      </el-col>
    </el-row>
    <br>

    <div slot="header" class="clearfix-school">
      <el-select
          v-model="dataSearch.idSchool"
          style="width: 350px"
          filterable
          placeholder="Lượng truy cập tính theo trường"
          clearable
          @change="getSchoolRequests(dataSearch.idSchool)"
      >
        <el-option
            v-for="item in schoolListSearch"
            :key="item.id"
            :value="item.id"
            :label="item.id+' - '+item.schoolName"
        ></el-option>
      </el-select>
    </div>

    <br>
    <el-row>
      <el-col :span="8">
        <el-card>
          <div slot="header" class="clearfix-now">
            <span>Requests hiện tại</span>
          </div>
          <div align="center">{{ schoolRequests.currentRequests }}</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div slot="header" class="clearfix-day">
            <span>Requests theo ngày</span>
          </div>
          <div align="center">{{ schoolRequests.dailyRequests }}</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div slot="header" class="clearfix-month">
            <span>Requests theo tháng</span>
          </div>
          <div align="center">{{ schoolRequests.monthlyRequests }}</div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AdminService from "@/services/AdminService/AdminService";
import {mapState} from "vuex";

export default {
  name: "ShowNumberOfRequest.vue",
  data() {
    return {
      allRequestSystem: {
        currentRequests: 0,
        dailyRequests: 0,
        monthlyRequests: 0,
      },
      schoolRequests: {
        currentRequests: 0,
        dailyRequests: 0,
        monthlyRequests: 0,
      },
      dataSearch: {
        idSchool: ''
      }

    };
  },
  created() {
    this.$store.dispatch('dashBoardStore/fetchDataAllSchool');
  },
  computed: {
    ...mapState('dashBoardStore', ['getAllSchoolDataList']),
    schoolListSearch() {
      return this.getAllSchoolDataList;
    }
  },
  mounted() {
    this.updateRequests();
    setInterval(this.updateRequests, 15000);
  },
  methods: {
    updateRequests() {
      AdminService.getRequestCount().then((resp) => {
        this.allRequestSystem.currentRequests = resp.data[0];
        this.allRequestSystem.dailyRequests = resp.data[1];
        this.allRequestSystem.monthlyRequests = resp.data[2];
      }).catch(err => {
        console.log(err)
      })
    },
    getSchoolRequests(id) {
      if (id == '') {
        this.schoolRequests.currentRequests = 0;
        this.schoolRequests.dailyRequests = 0;
        this.schoolRequests.monthlyRequests = 0;
        return
      }
      AdminService.getRequestCountByIdSchool(id)
          .then((resp) => {
            this.schoolRequests.currentRequests = resp.data[0];
            this.schoolRequests.dailyRequests = resp.data[1];
            this.schoolRequests.monthlyRequests = resp.data[2];
          }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.admin-dashboard {
  padding: 20px;
}

.clearfix-now {
  background-color: #ddbd52;
  font-size: large;
  height: 40px;
  font-weight: bold;
  text-align: center;
}

.clearfix-day {
  background-color: #5fb44a;
  font-size: large;
  height: 40px;
  font-weight: bold;
  text-align: center;
}

.clearfix-month {
  background-color: #c694f9;
  font-size: large;
  height: 40px;
  font-weight: bold;
  text-align: center;
}

</style>