var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "admin-dashboard" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix-now",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Requests hiện tại")])]
                ),
                _c("div", { attrs: { align: "center" } }, [
                  _vm._v(_vm._s(_vm.allRequestSystem.currentRequests)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix-day",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Requests theo ngày")])]
                ),
                _c("div", { attrs: { align: "center" } }, [
                  _vm._v(_vm._s(_vm.allRequestSystem.dailyRequests)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix-month",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Requests theo tháng")])]
                ),
                _c("div", { attrs: { align: "center" } }, [
                  _vm._v(_vm._s(_vm.allRequestSystem.monthlyRequests)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "div",
        {
          staticClass: "clearfix-school",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "350px" },
              attrs: {
                filterable: "",
                placeholder: "Lượng truy cập tính theo trường",
                clearable: "",
              },
              on: {
                change: function ($event) {
                  return _vm.getSchoolRequests(_vm.dataSearch.idSchool)
                },
              },
              model: {
                value: _vm.dataSearch.idSchool,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "idSchool", $$v)
                },
                expression: "dataSearch.idSchool",
              },
            },
            _vm._l(_vm.schoolListSearch, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: {
                  value: item.id,
                  label: item.id + " - " + item.schoolName,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix-now",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Requests hiện tại")])]
                ),
                _c("div", { attrs: { align: "center" } }, [
                  _vm._v(_vm._s(_vm.schoolRequests.currentRequests)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix-day",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Requests theo ngày")])]
                ),
                _c("div", { attrs: { align: "center" } }, [
                  _vm._v(_vm._s(_vm.schoolRequests.dailyRequests)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix-month",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Requests theo tháng")])]
                ),
                _c("div", { attrs: { align: "center" } }, [
                  _vm._v(_vm._s(_vm.schoolRequests.monthlyRequests)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }